<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mgrupommpp-modals
			:modelName="modelName"
		></mgrupommpp-modals>
	</div>
</template>

<script>
import mgrupommppActions from './MgrupommppActions';
import mgrupommppModals from './MgrupommppModals.vue';

export default {
	name: 'mgrupommpp-grid',
	components: {
		'mgrupommpp-modals': mgrupommppModals
	},
	data() {
		return {
			modelName: 'mgrupommpp',
			actions: mgrupommppActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
